<template lang="pug">
  .index-c-w
    .main
      .top-text 在浙江省残疾人联合会的统一领导下，平台各方紧密团结，以服务残疾人为核心，切实履行各自的职责， 不断创新服务方式，优化工作流程，确保工作落实，助力浙江省残疾人事业现代化新征程。
      .r-360-w(id="r360" :style="{height:H+'px',width:H+'px'}")
        .comp.comp1(@click="onCompany(1)" :style="{height:H/4+'px',width:H/4+'px'}")
          .t-s(v-if="W") {{companyName}}
          .t-n(v-else) {{companyName}}
        .comp.comp2(@click="onCompany(2)" :style="{height:H/4+'px',width:H/4+'px'}")
          .t-s(v-if="W") {{companyName1}}
          .t-n(v-else) {{companyName1}}
        .comp.comp3( @click="onCompany(3)" id="companyItem" :style="{height:H/4+'px',width:H/4+'px'}")
          .t-s(v-if="W") {{companyName2}}
          .t-n(v-else) {{companyName2}}
        .rc-box
          .province-box(@click="onCompany(4)" :style="{height:H/2.6+'px',width:H/2.6+'px'}")
            span 浙江省<br/>残疾人联合会
      .relation(v-if='relationShow')
        .relation-top-bline1
        .relation-top-bline
          div
        .relation-item(v-for="item in insurance" @click="onInsurance(item)") 
          .item-b 
            .line
            .item-text {{item.name}}
</template>

<script>
export default {
  data() {
    return {
      H: 0,
      CH: 0,
      W: 0,
      enterCount: false,
      relationShow: false,
      companyName: "各地市残疾人联合会",
      companyName1: "广润保险经纪有限公司",
      companyName2: "保险共同体",
      insurance: [
        {
          name: "人保财险",
          parentId: 1,
          companyId: 1,
        },
        {
          name: "太保财险",
          parentId: 2,
          companyId: 2,
        },
        {
          name: "平安财险",
          parentId: 3,
          companyId: 3,
        },
        {
          name: "大地财险",
          parentId: 4,
          companyId: 4,
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.reSizeDiv();
  },
  methods: {
    //动态设置div 高度
    reSizeDiv() {
      let div = document.getElementById("r360");
      this.H = div.clientHeight;
      this.CH = document.getElementById("companyItem").clientHeight;
    },
    //保险公司点击事件 跳转到对应的页面 路由切换
    onInsurance(item) {
      const { parentId, name, companyId } = item;
      this.$router.push({
        name: "organization/list",
        query: {
          companyType: 3,
          companyName: name,
          parentId,
          companyId: companyId,
        },
      });
    },
    //四大组件点击事件 1 残联市县 2 保险公司 3 第三方公司 4 残联省级
    onCompany(idx) {
      if (idx === 3) {
        this.enterCount = !this.enterCount;
        if (this.enterCount) {
          this.relationShow = true;
          this.H = this.H - this.CH * 5;
          this.W = 38;
        } else {
          this.relationShow = false;
          this.H = this.H + this.CH * 5;
          this.W = 0;
        }
      } else if (idx === 1) {
        this.$router.push({
          path: "/organization/list",
          query: { companyType: idx, parentId: 1 },
        });
      } else {
        this.$router.push({
          path: "/organization/list",
          query: { companyType: idx, parentId: 0 },
        });
      }
    },
    //重置窗口大小
    windowResize() {
      let _this = this;
      window.onresize = function() {
        // 定义窗口大小变更通知事件
        _this.$forceUpdate();
      };
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 68%;
  margin: 0 auto;
  .df;
  .dfc;
}
.top-text {
  font-size: 20px;
  color: #2067c2;
  line-height: 28px;
  text-indent: 2em;
  margin-top: 20px;
}
.relation {
  height: 160px;
  width: 100%;
  .df;
  .jcsa;
  position: relative;
  padding-top: 20px;
  .relation-top-bline1 {
    position: absolute;
    top: -38%;
    height: 45px;
    border-left: 1px solid #2eaae3;
  }
  .relation-top-bline {
    position: absolute;
    top: -10%;
    width: 75%;
    div {
      width: 100%;
      border-top: 1px solid #2eaae3;
    }
  }
  .relation-item {
    flex: 1;
    height: 100%;
    // background-color: #040b3f;
    position: relative;
    cursor: pointer;
    .df;
    .jcc;
    .item-b {
      position: absolute;
      left: 50%;
      top: -30px;
      transform: translateX(-50%);
      width: 222px;
      height: 164px;
      padding-bottom: 20px;
      background: url("../../assets/image/compang.png") 0 0 no-repeat;
      background-size: 100%;
      .df;
      .jcc;
      align-items: flex-end;
      &:hover {
        background: url("../../assets/image/compang-hover.png") 0 0 no-repeat;
        background-size: 100%;
        .item-text {
          color: #fff;
        }
      }
      .item-text {
        font-size: 20px;
        color: #6ad0ff;
      }
      .line {
        position: absolute;
        top: -6px;
        left: 51%;
        width: 2px;
        height: 20px;
        border-left: 1px solid #2eaae3;
        transform: translateX(-50%);
      }
    }
  }
}
.r-360-w {
  flex: 1;
  margin: 0 auto;
  position: relative;
  padding: 90px;
  top: -60px;
  .rc-box {
    width: 100%;
    height: 100%;
    background-image: url("../../assets/image/bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .province-box {
      background-image: url("../../assets/image/province.png");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      cursor: pointer;
      margin-top: 4px;
      span {
        font-size: 1.5rem;
        text-align: center;
        color: #6ad0ff;
        line-height: 1.875rem;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .province-box:hover {
      width: 100%;
      height: 100%;
      background-image: url("../../assets/image/province-hover.png");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
    }
  }
  .comp {
    position: absolute;
    width: 179px;
    height: 179px;
    .df;
    .dfc;
    .jcc;
    border-radius: 50%;
    background: url("../../assets/image/gc.png") 0 0 no-repeat;
    background-size: 100%;
    padding: 16px;
    cursor: pointer;
    &:hover {
      background: url("../../assets/image/gc-hover.png") 0 0 no-repeat;
      background-size: 100%;
    }
    .t-n {
      font-size: 24px;
      text-align: center;
      color: #6ad0ff;
      line-height: 30px;
    }
    .t-s {
      font-size: 16px;
      text-align: center;
      color: #6ad0ff;
      line-height: 30px;
    }
  }
  .comp1 {
    top: 36%;
    left: 0;
    transform: translateY(-50%);
  }
  .comp2 {
    top: 36%;
    right: 0;
    transform: translateY(-50%);
  }
  .comp3 {
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }
}
</style>
